import React, { useState } from "react";
import { Modal, Button, Form, DatePicker, Row, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setSessionModals,
  setSessionDetail,
} from "../../../redux/sessionSlice";
import AgGridTable from "../../AgGridTable";
import dayjs from "dayjs";
import useSlots from "../../../hooks/useSlots";
import { AgGridReact } from "ag-grid-react";

export default function HourUpdateModal() {
  const [getExpertAvailabilities] = useSlots();
  const dispatch = useDispatch();
  const [sessionYearTime, setSessionYearTime] = useState();

  const isOpen = useSelector((state) => state.session.modals.sessionHourUpdate);

  const hourOptions = useSelector((state) => state.session.slot.slotHours);
  const sessionState = useSelector((state) => state.session.sessionDetail);

  const [columnDefs, _] = useState([
    {
      field: "label",
      headerName: "Saat",
      flex: 1,
    },
    {
      field: "reservable",
      headerName: "Durum",
      flex: 1,
      cellRenderer: (params) => {
        return params.value ? "Uygun" : "Uygun Değil";
      },
    },
  ]);

  const onChangeSessionTime = (value) => {
    setSessionYearTime(value);
    getExpertAvailabilities({
      expertId: sessionState.expertId,
      sessionTime: value,
    });
  };

  const onRowClicked = (params) => {
    if (!params.data.reservable) {
      message.error("Saat uygun değil.");
      return;
    }

    const [hour, min] = params.data.label
      .split(":")
      .map((item) => Number(item));
    const sessionCurrentTime = dayjs(
      !sessionYearTime ? sessionState.sessionTime : sessionYearTime
    )
      .set("hour", hour)
      .set("minute", min)
      .format("YYYY-MM-DDTHH:mm:00");

    dispatch(
      setSessionDetail({
        ...sessionState,
        hourInfo: params.data.label,
        sessionCurrentTime,
      })
    );
    dispatch(setSessionModals({ sessionHourUpdate: false }));
  };
  const getRowStyle = (params) => {
    let styles = {
      cursor: "pointer",
    };
    if (!params.data.reservable) {
      styles = {
        ...styles,
        cursor: "not-allowed",
      };
    }
    if (params.data.label === sessionState.hourInfo) {
      styles = {
        ...styles,
        background: "#E5E7E9",
      };
    }
    return styles;
  };
  return (
    <Modal
      centered
      onCancel={() => dispatch(setSessionModals({ sessionHourUpdate: false }))}
      width={1000}
      title="Saati Düzenle"
      open={isOpen}
      footer={false}
    >
      <div className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
        <AgGridReact
          rowData={hourOptions}
          columnDefs={columnDefs}
          onGridReady={(params) => {
            params.api.sizeColumnsToFit();
          }}
          getRowStyle={getRowStyle}
          onRowClicked={onRowClicked}
          suppressRowClickSelection={true}
          domLayout="normal"
        />
      </div>
    </Modal>
  );
}

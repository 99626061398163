import axios from "axios";
const getToken = () => {
  let token = localStorage.getItem("token") || "";
  token = token.substring(1);
  token = token.substring(0, token.length - 1);
  return token;
};

const token = getToken();
const baseURL = "https://healmeup-test.internalapps.rocks/api";
const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${JSON.parse(JSON.stringify(token))}`,
    "X-App-Type": "expert",
  },
});

export default instance;

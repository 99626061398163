import React, { useMemo, useCallback, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

import { Form, Input, Skeleton } from "antd";
import CustomSpinner from "./Spinner";
const AgGridTable = (props) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const gridRef = useRef();

  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    }),
    []
  );

  const gridStyle = useMemo(
    () => ({
      flex: 1,
      width: "100%",
      height: props.height || "calc(100vh - 200px)",
    }),
    [props.height]
  );

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
    ...props.defaultColDef,
  };
  const localeText = {
    searchOoo: "Ara",
    filters: "Filtreler",
    selectAll: "(Hepsini Seç)",
    cut: "Kes",
    copy: "Kopyala",
    paste: "Yapıştır",
    copyWithHeaders: "Başlıklar ile kopyala",
    copyWithGroupHeaders: "Grup başlığı ile kopyala",
    pinColumn: "Alanı işaretle",
    noPin: "Yok",
    pinLeft: "Sola işaretle",
    pinRight: "Sağa işaretle",
    autosizeThiscolumn: "Bu alanı otomatik genişlet",
    autosizeAllColumns: "Bütün alanı otomatik genişlet",
    resetColumns: "Alanı sıfırla",
    export: "Dışarı Aktar",
    csvExport: ".csv indir",
    excelExport: "Excel indir",
    columns: "Kolonlar",
    pageSizeSelectorLabel: "Görüntüle",
    page: "Sayfa",
  };
  const onFilterTextBoxChanged = useCallback(() => {
    const searchText = document.getElementById("filter-text-box").value;
    gridRef.current.api.setQuickFilter(searchText);
  }, []);

  const closeHeader = "closeHeader" in props;

  // Grid hazır olduğunda çağrılacak
  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    // Grid API'sini props üzerinden erişilebilir yapıyoruz
    if (props.onGridReady) {
      props.onGridReady(params);
    }
  };

  return (
    <div style={containerStyle}>
      {!closeHeader && (
        <div className="ag-header" style={props.headerStyle}>
          <Form.Item className="mb-0 p-0 col-4">
            <Input
              className="ag-search-input"
              label="Ara"
              type="text"
              id="filter-text-box"
              placeholder="Ara"
              onChange={onFilterTextBoxChanged}
            />
          </Form.Item>
          <div className="ag-header-children">{props.headerChildren}</div>
        </div>
      )}
      {props.infoTopChildren}
      <div
        className={`ag-theme-balham${
          theme === "default" ? "" : theme === "dark" ? "-dark" : ""
        }`}
        style={gridStyle}
      >
        {props.loading ? (
          <div className="column mt-5">
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </div>
        ) : (
          <AgGridReact
            onPaginationChanged={({ newPage }) => {
              if (!gridRef.current || !newPage) {
                return;
              }
              const currentPage =
                gridRef.current.api.paginationGetCurrentPage();
              if (props.getCurrentPage) {
                props.getCurrentPage(currentPage);
              }
            }}
            pagination={true}
            paginationAutoPageSize={true}
            infiniteInitialRowCount={1}
            cacheBlockSize={100}
            maxBlocksInCache={100}
            localeText={localeText}
            loadingOverlayComponent={() => <CustomSpinner />}
            noRowsOverlayComponent={() => "Veri bulunamadı."}
            ref={gridRef}
            getRowId={(params) => {
              if (!params.data) return null;

              if (params.data.sortOrder !== undefined) {
                return params.data.sortOrder;
              }

              return (
                params.data?.id ||
                params.data?.expertId ||
                params.data?.userId ||
                params.data?.sessionId ||
                params.data?.feedbackId ||
                params.data?.templateId ||
                params.node?.id
              );
            }}
            {...props}  
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            domLayout="normal"
            suppressColumnVirtualisation={true}
            suppressRowVirtualisation={true}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            animateRows={false}
            suppressAnimationFrame={true}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            onFilterChanged={(params) => {
              setTimeout(() => {
                params.api.sizeColumnsToFit();
              }, 0);
            }}
            sideBar={{
              toolPanels: [
                {
                  id: "columns",
                  labelDefault: "Columns",
                  toolPanel: "agColumnsToolPanel",
                  toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressSideButtons: true,
                  },
                },
                {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel",
                },
              ],
              hiddenByDefault: false,
            }}
            suppressMoveWhenRowDragging={true}
            onRowDragEnd={(event) => {}}
          />
        )}
      </div>
    </div>
  );
};

export default AgGridTable;
